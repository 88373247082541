<template>
  <div class="container">
    <van-tabs v-model="active" sticky animated color="#02DBCA" @click="switchType">
      <van-tab v-for="(item,index) in DataList" :title="item.name" :key="index">
        <div  class="recruitment" v-for="(Job_item,J_index) in item.JobList"  :key="J_index">
          <van-cell class="recruitment_item" :title="Job_item.name" is-link @click="postDetail(Job_item)"/>
        </div>
<!--        <van-collapse accordion :border="false" class="recruitment" v-model="coll_active">-->
<!--          <van-collapse-item v-for="(Job_item,J_index) in item.JobList" :key="J_index" size="large" class="recruitment_item" :title="Job_item.name">-->
<!--&lt;!&ndash;            <div class="txt" v-html="Job_item.dec"></div>&ndash;&gt;-->
<!--            <div class="submit">-->
<!--              <van-button @click="postDetail(Job_item)" class="btn detail" type="info" size="small">岗位详情</van-button>-->
<!--              <van-button @click="Application(Job_item)" class="btn" size="small">应聘岗位</van-button>-->
<!--            </div>-->
<!--          </van-collapse-item>-->
<!--        </van-collapse>-->
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { GetPostType, GetPostList } from "@/api/interview";
import {mapState} from 'vuex'
export default {
  name: "RecruitmentList",
  async created() {
    this.$store.dispatch('changeLocation', '招聘岗位')
    await this.fetchData();
    if(this.activeTabKey !== ''){
      this.active = this.activeTabKey;
      await this.GetPostList(this.activeTabKey)
    }else{
      await this.GetPostList(0)
    }
    console.log(this.$route);
  },
  computed: mapState([
    'activeTabKey'
  ]),
  data() {
    return {
      active: 0,
      coll_active: '0',
      DataList: [],
      lastIndex : -1,
      status: -2
    }
  },
  methods: {
    postDetail(item){
      // console.log(item);
      this.$store.dispatch('SaveActiveTab', this.active)
      this.$router.push({
        name: 'PostDetail',
        query: {
          id : item.id
        }
      })
    },
    async switchType(name, title){
      if(this.lastIndex != name){
        this.coll_active = []
      }
      this.GetPostList(name)
    },
    async fetchData(){
      await GetPostType().then(res=>{
        if(res.errcode == 200){
          this.DataList = res.rows;
            this.DataList.forEach(item=> item.JobList= [])
        }
      })
    },
    async GetPostList(i){
      if(this.DataList[i].JobList.length != 0){
        return
      }
      let post_type_code = this.DataList[i].code
      await GetPostList({offset:0,limit:100,post_type_code}).then(res=>{
        if(res.errcode == 200) {
          this.$nextTick(()=>{
            this.DataList[i].JobList = res.rows;
            this.$forceUpdate()
          })
        }
      })
      this.lastIndex = i;
    }
  }
}
</script>

<style scoped lang="scss">
  .container{
    .recruitment{
      margin-top: 10px;
      .recruitment_item{
        width: 355px;
        background: #FFFFFF;
        margin: 0 auto 15px;
        border-radius: 5px;
        box-shadow: 0px 1px 7px 0px rgba(153, 153, 153, 0.25);
        .txt{
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 300;
          line-height: 20px;
          color: #000;
        }
        .submit{
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 2px;
          .btn{
            box-sizing: border-box;
            border:1px solid #02DBCA;
            border-radius: 4px;
            padding: 0 15px;
            color: #02DBCA;
            background-color: #fff;
          }
          .detail{
            color: #1989fa;
            border-color: #1989fa;
          }
        }
      }
    }
  }
</style>
